import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { useCommon, useException, useNotificacion } from '../../hooks';

export function SkyFormExcepciones({ formatMarca, onSwitch }) {
    const toast = useRef(null);

    const [latenessDia, setLatenessDia] = useState(0);
    const [latenessNoche, setLatenessNoche] = useState(0);

    const [latenessDia_real, setLatenessDia_real] = useState(0);
    const [latenessNoche_real, setLatenessNoche_real] = useState(0);
    const [noCallNoShow_real, setNoCallNoShow_real] = useState(0);
    const [unpaidExcessLoginDia, setUnpaidExcessLoginDia] = useState(0);
    const [unpaidExcessLoginNoche, setUnpaidExcessLoginNoche] = useState(0);
    const [noCallNoShow, setNoCallNoShow] = useState(0);
    const [pausaDefinicion, setPausaDefinicion] = useState('');

    const [previewImage, setPreviewImage] = useState(null);

    const { getPausasFilter, listPausas } = useCommon();
    const { insertException, validException } = useException();
    const { addNotificacion, addAsignacionNotificacion } = useNotificacion();

    const onDrop = useCallback(async (acceptedFile) => {
        const file = acceptedFile[0];
        await formik.setFieldValue('ImageJustificacion', file);
        setPreviewImage(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    const formik = useFormik({
        initialValues: inititalValue(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {
            const response_notificacion = await addNotificacion({
                Modulo: 2,
                Descripcion: `Excepcion Ingresada para ${formatMarca.Badge} - ${formatMarca.Nombre} - ${formatMarca.Fecha}`
            });

            await addAsignacionNotificacion({
                Notificacion: response_notificacion.id,
                BadgeAsignacion: 'SV2091',
                Estado: 0
            });

            let horas_fixed = formValue.Horas;

            await insertException(
                {
                    Observacion: formValue.Comentario,
                    Horas: horas_fixed.toFixed(4),
                    Pausa: {
                        code: formValue.Pausa.id_pausa
                    },
                    ImageJustificacion: formValue.ImageJustificacion
                },
                {
                    idEmpleado: formatMarca.ID_Empleado,
                    Fecha: formatMarca.Fecha
                }
            );

            onSwitch();
        }
    });

    useEffect(() => {
        (async () => {
            await getPausasFilter('', '', '');
            formik.setFieldValue('ID_Marca', formatMarca.ID_Marca);
        })();
    }, []);

    useEffect(() => {
        let calc_lateness_dia = formatMarca.HorasTrabajadasCrudo_dia + formatMarca.HorasDevengadas_dia + formatMarca.HorasOffline_dia;
        calc_lateness_dia = formatMarca.HorasProgramadas > calc_lateness_dia ? formatMarca.HorasProgramadas - calc_lateness_dia : 0;

        let calc_lateness_noche = formatMarca.HorasTrabajadsaCrudo_noche + formatMarca.HorasDevengadas_noche + formatMarca.HorasOffline_noche;
        calc_lateness_noche = formatMarca.HorasProgramadas_noche > calc_lateness_noche ? formatMarca.HorasProgramadas_noche - calc_lateness_noche : 0;

        let calc_noCallNoShow = formatMarca.HorasTrabajadasCrudo_dia + formatMarca.HorasDevengadas_dia + formatMarca.HorasOffline_dia + formatMarca.HorasTrabajadsaCrudo_noche + formatMarca.HorasDevengadas_noche + formatMarca.HorasOffline_noche;

        if (calc_noCallNoShow == 0) {
            setNoCallNoShow(formatMarca.HorasProgramadas_noche + formatMarca.HorasProgramadas - formik.values.Horas);
            setNoCallNoShow_real(formatMarca.HorasProgramadas_noche + formatMarca.HorasProgramadas)
            setLatenessDia(0);
            setLatenessNoche(0);
        } else {
            let list_lateness_dia = [
                2, 121, 18, 6, 7, 9, 8, 16, 10, 17, 15, 14, 11, 13, 12, 38, 102, 56, 37, 101, 39, 55, 36, 33, 32, 41, 42, 34, 43, 94, 95, 97, 98, 99, 100, 35, 45, 46, 50, 51, 53, 54, 58, 59, 87, 88, 89, 90, 91, 92, 93, 116, 44, 47, 48, 49
            ];
            let list_lateness_noche = [
                3, 78, 66, 67, 69, 68, 76, 70, 77, 75, 74, 71, 73, 72, 121, 112, 115, 81, 80, 105, 113, 104, 111, 106, 114, 83, 84, 82, 63, 35, 45, 46, 50, 51, 53, 54, 58, 59, 87, 88, 89, 90, 91, 92, 93, 116, 107, 108, 109, 110, 119, 120, 44, 47, 48,
                49
            ];
            setNoCallNoShow(0);
            if (formik.values.Horas == 0) {
                setLatenessDia(calc_lateness_dia);
                setLatenessNoche(calc_lateness_noche);
            }

            setLatenessDia_real(calc_lateness_dia);
            setLatenessNoche_real(calc_lateness_noche);

            if (list_lateness_dia.includes(formik.values.Pausa.id_pausa)) {
                setLatenessDia(calc_lateness_dia - formik.values.Horas);
            }

            if (list_lateness_noche.includes(formik.values.Pausa.id_pausa)) {
                setLatenessNoche(calc_lateness_noche - formik.values.Horas);
            }
        }
    }, [formik.values.Horas]);

    const onValidPausa = (e) => {
        let pausa_prohibidas = [2, 31, 121, 18, 6, 7, 9, 8, 16, 10, 17, 15, 14, 11, 13, 12, 3, 78, 66, 67, 69, 68, 76, 70, 77, 75, 74, 71, 73, 72, 1, 62, 57, 64, 52, 30, 65, 4, 5];

        if (!pausa_prohibidas.includes(e.value.id_pausa)) {
            formik.setFieldValue('Pausa', e.value);
            setPausaDefinicion(e.value.definicion);
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Esta pausa no es permitido usarla' });

            formik.setFieldValue('Pausa', {});
            setPausaDefinicion('');
        }
    };

    const onDataHours = (e) => {
        let list_lateness_dia = [
            2, 121, 18, 6, 7, 9, 8, 16, 10, 17, 15, 14, 11, 13, 12, 38, 102, 56, 37, 101, 39, 55, 36, 33, 32, 41, 42, 34, 43, 94, 95, 97, 98, 99, 100, 35, 45, 46, 50, 51, 53, 54, 58, 59, 87, 88, 89, 90, 91, 92, 93, 116, 44, 47, 48, 49
        ];
        let list_lateness_noche = [
            3, 78, 66, 67, 69, 68, 76, 70, 77, 75, 74, 71, 73, 72, 121, 112, 115, 81, 80, 105, 113, 104, 111, 106, 114, 83, 84, 82, 63, 35, 45, 46, 50, 51, 53, 54, 58, 59, 87, 88, 89, 90, 91, 92, 93, 116, 107, 108, 109, 110, 119, 120, 44, 47, 48, 49
        ];

        if (e.value > 24) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Has superado el limite de horas diarias' });
            formik.setFieldValue('Horas', '');
        } else {
            let bandera_ncns = false;
            let bandera_show_error = false;

            if (list_lateness_dia.includes(formik.values.Pausa.id_pausa)) {
                //modificar horas lateness_dia
                if (latenessDia_real > 0) {
                    if (e.value > latenessDia_real) {
                        bandera_show_error = true;
                    }
                } else {
                    bandera_ncns = true;
                }
            }

            if (list_lateness_noche.includes(formik.values.Pausa.id_pausa)) {
                //modificar horas lateness_noche
                if (latenessNoche_real > 0) {
                    if (e.value > latenessNoche_real) {
                        bandera_show_error = true;
                    }
                } else {
                    bandera_ncns = true;
                }
            }

            if (bandera_ncns) {
                if (e.value > noCallNoShow_real) {
                    bandera_show_error = true;
                }
            }

            console.log(bandera_show_error)
            console.log(noCallNoShow_real)

            if (bandera_show_error) {
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Has superado el limite de horas a justificar' });
                formik.setFieldValue('Horas', '0');
            } else {
                formik.setFieldValue('Horas', e.value);
            }
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <h4>Ingreso de Excepciones Skyverse</h4>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12">
                        <div className="card overview-box" style={{ backgroundColor: 'var(--indigo-400)', color: 'white' }}>
                            <div className="overview-info">
                                <h6>Definicion de la Pausa</h6>
                                <h5>{pausaDefinicion}</h5>
                            </div>
                            <i className="pi pi-info"></i>
                        </div>
                    </div>
                </div>

                <div className="grid my-3">
                    <div className="col-12">
                        <span className="p-float-label">
                            <Dropdown id="Pausa" name="Pausa" className="w-full" filter value={formik.values.Pausa} options={listPausas} optionLabel="name" onChange={onValidPausa} />
                            <label htmlFor="Pausa">Pausa</label>
                        </span>
                    </div>
                </div>

                <div className="grid my-3">
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputTextarea id="Comentario" name="Comentario" className="w-full" value={formik.values.Comentario} onChange={(e) => formik.setFieldValue('Comentario', e.target.value)} />
                            <label htmlFor="Comentario">Comentario</label>
                        </span>
                    </div>
                </div>

                <div className="grid my-3">
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputNumber id="Horas" name="Horas" className="w-full" minFractionDigits={2} maxFractionDigits={5} value={formik.values.Horas} onChange={onDataHours} />
                            <label htmlFor="Horas">Horas</label>
                        </span>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12">
                                <Button type="button" {...getRootProps()}>
                                    Subir imagen
                                </Button>
                                <input {...getInputProps()} />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <Image src={previewImage} width="100%" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid my-3">
                    <div className="col-12 text-right">
                        <Button severity="primary" type="submit" size="large">
                            Guardar Excepcion
                        </Button>
                    </div>
                </div>
            </form>

            <div className="grid">
                <div className="md:col-4 col-12">
                    <div className="card overview-box blue" onClick={() => formik.setFieldValue('Horas', latenessDia)}>
                        <div className="overview-info">
                            <h6>Lateness Dia</h6>
                            <h1>{latenessDia.toFixed(4)}</h1>
                        </div>
                        <i className="pi pi-clock"></i>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className="card overview-box blue">
                        <div className="overview-info">
                            <h6>Lateness Noche</h6>
                            <h1>{latenessNoche.toFixed(4)}</h1>
                        </div>
                        <i className="pi pi-clock"></i>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className="card overview-box blue">
                        <div className="overview-info">
                            <h6>No Call No Show</h6>
                            <h1>{noCallNoShow.toFixed(4)}</h1>
                        </div>
                        <i className="pi pi-clock"></i>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className="card overview-box gray">
                        <div className="overview-info">
                            <h6>Unpaid Excess Login Dia</h6>
                            <h1>0.00</h1>
                        </div>
                        <i className="pi pi-clock"></i>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className="card overview-box gray">
                        <div className="overview-info">
                            <h6>Unpaid Excess Login Noche</h6>
                            <h1>0.00</h1>
                        </div>
                        <i className="pi pi-clock"></i>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className="card overview-box" style={{ backgroundColor: 'var(--teal-500)', color: 'white' }}>
                        <div className="overview-info">
                            <h6>Horas Programadas</h6>
                            <h1>{(formatMarca.HorasProgramadas_noche + formatMarca.HorasProgramadas).toFixed(4)}</h1>
                        </div>
                        <i className="pi pi-clock"></i>
                    </div>
                </div>
            </div>
        </>
    );
}

function inititalValue() {
    return {
        ID_Marca: '',
        Pausa: '',
        Comentario: '',
        Horas: '',
        ImageJustificacion: ''
    };
}

function validationSchema() {
    return {
        ID_Marca: Yup.string(),
        Pausa: Yup.object().required(true),
        Comentario: Yup.string().required(true),
        Horas: Yup.number().required(true),
        ImageJustificacion: Yup.string()
    };
}
