import { BASE_API } from '../../utilities/constants';

export async function getAprobacionApsApi(token, Ap = '', Aprobado = '') {
    try {
        const apFilter = `Ap=${Ap}`;
        const aprobadoFilter = `Aprobado=${Aprobado}`;
        const url = `${BASE_API}v1/AprobacionAp/?${apFilter}&${aprobadoFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addAprobacionApApi(data, token) {
    try {
        const url = `${BASE_API}v1/AprobacionAp/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendEmailNotificationApi(data, token) {
    try {
        const url = `${BASE_API}v1/SendEmailNotification/`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateAprobacionApApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/AprobacionAp/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
