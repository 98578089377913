import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

export function DraggableSam(props) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.id,
        data: {
            type: 'type2'
        }
    });

    const onPassPhase = () => {
        
        props.preLeadset((prev) => prev.filter((item) => item !== props.id));

        props.leadset((prev) => [...prev, props.id]);
    }

    return (
        <Card id={props.id} ref={setNodeRef} {...attributes} className="my-3">
            <div className="grid">
                <div className="col-8">
                    <div className='grid'>
                        <div className='col-12'>

                            {props.children}

                        </div>
                        <div className='col-12'>
                            <div className='grid'>
                                <div className='col-3'>
                                    <Button severity="danger" type="button" key={props.id} id={props.id} icon="pi pi-times" />
                                </div>
                                <div className='col-3'>
                                    <Button severity="info" type="button" key={props.id} id={props.id} icon="pi pi-comments" />
                                </div>
                                <div className='col-3'>
                                    <Button severity="success" type="button" key={props.id} id={props.id} icon="pi pi-check" onClick={onPassPhase} />
                                </div>
                                <div className='col-3'>
                                    <Button severity="primary" type="button" key={props.id} id={props.id} onClick={(e) => props.visibleFunc(true)} icon="pi pi-eye" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-4 text-center">
                    <div className='grid'>
                        <div className='col-12'>
                            <i {...listeners} className="pi pi-align-justify" style={{fontSize: "1.5rem"}}></i>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}
