import React, { useEffect, useState } from 'react';
import {size} from "lodash"
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

export function ProductionTab({ formik, cuenta, lob, subLob, supervisor, accountManager, director, loadingShow, hr_generalist }) {

    const [listSupsAccount, setListSupsAccount] = useState([]);

    useEffect(() => {
        if (size(supervisor) > 0 && size(accountManager) > 0 && size(director) > 0 && size(hr_generalist) > 0){
            const new_list = [...supervisor, ...accountManager, ...director, ...hr_generalist]
            setListSupsAccount(new_list)
        }
    }, [supervisor,accountManager, director, hr_generalist])

    return (
        <div className="grid mt-5 p-3">
            <div className="col-12">
                <h3>Informacion de Operaciones</h3>
                <div className="grid mt-5">
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown filter className="w-full" id="Cuenta" name="Cuenta" value={formik.values.Cuenta} onChange={(e) => formik.setFieldValue('Cuenta', e.value)} options={cuenta} optionLabel="name" />
                            <label htmlFor="Cuenta">Nombre de Cuenta</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" id="Lob" name="Lob" value={formik.values.Lob} onChange={(e) => formik.setFieldValue('Lob', e.value)} options={lob} optionLabel="name" />
                            <label htmlFor="Lob">Lob</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown className="w-full" id="SubLob" name="SubLob" value={formik.values.SubLob} onChange={(e) => formik.setFieldValue('SubLob', e.value)} options={subLob} optionLabel="name" />
                            <label htmlFor="SubLob">Lob 2</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <label htmlFor="">Lob 3</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                filter
                                className="w-full"
                                id="Supervisor"
                                name="Supervisor"
                                value={formik.values.Supervisor}
                                onChange={(e) => {
                                    formik.setFieldValue('Supervisor', e.value);
                                    formik.setFieldValue('SupervisorName', e.value.nombre_sup);
                                }}
                                options={listSupsAccount}
                                optionLabel="name"
                                editable
                            />
                            <label htmlFor="Supervisor">Badge Supervisor {loadingShow ?? (<i className='pi pi-spin pi-spinner'></i>)}</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="SupervisorName" id="SupervisorName" readOnly={true} value={formik.values.SupervisorName} onChange={formik.handleChange} />
                            <label htmlFor="SupervisorName">Supervisor</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                filter
                                className="w-full"
                                id="AccountManager"
                                name="AccountManager"
                                value={formik.values.AccountManager}
                                onChange={(e) => {
                                    formik.setFieldValue('AccountManager', e.value);
                                    formik.setFieldValue('AccountManagerName', e.value.nombre_sup);
                                }}
                                options={listSupsAccount}
                                optionLabel="name"
                                editable
                            />
                            <label htmlFor="AccountManager">Badge Account Manager</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="AccountManagerName" id="AccountManagerName" readOnly={true} value={formik.values.AccountManagerName} onChange={formik.handleChange} />
                            <label htmlFor="AccountManagerName">Account Manager</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Dropdown
                                filter
                                className="w-full"
                                id="Director"
                                name="Director"
                                value={formik.values.Director}
                                onChange={(e) => {
                                    formik.setFieldValue('Director', e.value);
                                    formik.setFieldValue('DirectorName', e.value.nombre_sup);
                                }}
                                options={listSupsAccount}
                                optionLabel="name"
                                editable
                            />
                            <label htmlFor="Director">Badge Director</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" name="DirectorName" id="DirectorName" readOnly={true} value={formik.values.DirectorName} onChange={formik.handleChange} />
                            <label htmlFor="DirectorName">Director</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar className="w-full" name="HireDT" id="HireDT" dateFormat="dd/mm/yy" value={formik.values.HireDT} onChange={(e) => formik.setFieldValue('HireDT', e.value)} />
                            <label htmlFor="HireDT">Hire Date</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar className="w-full" name="TrainingDT" id="TrainingDT" dateFormat="dd/mm/yy" value={formik.values.TrainingDT} onChange={(e) => formik.setFieldValue('TrainingDT', e.value)} />
                            <label htmlFor="TrainingDT">Training Date</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar className="w-full" name="ProductionDT" id="ProductionDT" dateFormat="dd/mm/yy" value={formik.values.ProductionDT} onChange={(e) => formik.setFieldValue('ProductionDT', e.value)} />
                            <label htmlFor="ProductionDT">Production Date</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar className="w-full" name="Lob2DT" id="Lob2DT" dateFormat="dd/mm/yy" value={formik.values.Lob2DT} onChange={(e) => formik.setFieldValue('Lob2DT', e.value)} />
                            <label htmlFor="Lob2DT">Lob 2 Date</label>
                        </span>
                    </div>
                    <div className="col-12 md:col-6">
                        <span className="p-float-label">
                            <Calendar className="w-full" name="Lob3DT" id="Lob3DT" dateFormat="dd/mm/yy" value={formik.values.Lob3DT} onChange={(e) => formik.setFieldValue('Lob3DT', e.value)} />
                            <label htmlFor="Lob3DT">Lob 3 Date</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
