import { BASE_API } from '../../utilities/constants';

export async function getApsApi(token, NumAp = '', Badge = '') {
    try {
        const numApFilter = `id=${NumAp}`;
        const badgeFilter = `Empleado__Badge=${Badge}`;

        const url = `${BASE_API}v1/Ap/?${numApFilter}&${badgeFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getValidApPendingApi(token, Empleado = '', TipoAp = '') {
    try {
        const empleadoFilter = `Empleado=${Empleado}`;
        const tipoApFilter = `TipoAp=${TipoAp}`;

        const url = `${BASE_API}v1/ReportApValidPending/?${empleadoFilter}&${tipoApFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getReportAttritionApi(token, FechaInicio='', FechaFinal='') {
    try {
        const fechaInicioFilter = `FechaInicio=${FechaInicio}`
        const fechaFinalFilter = `FechaFinal=${FechaFinal}`

        const url = `${BASE_API}v1/ReportApAttrition/?${fechaInicioFilter}&${fechaFinalFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function getReportAutomateAp(token, FechaInicio = '', FechaFinal = '', Cuenta = '', TipoAp='', Empleado='', Pendiente='', NumAp='') {
    try {
        const FechaInicioFilter = `FechaInicio=${FechaInicio}`;
        const FechaFinalFilter = `FechaFinal=${FechaFinal}`;
        const CuentaFilter = `Cuenta=${Cuenta}`
        const TipoApFilter = `TipoAp=${TipoAp}`
        const EmpleadoFilter = `Empleado=${Empleado}`
        const PendienteFilter = `Pendiente=${Pendiente}`
        const numApFilter = `NumAp=${NumAp}`;

        const url = `${BASE_API}v1/ReportAutoApView/?${FechaInicioFilter}&${FechaFinalFilter}&${CuentaFilter}&${TipoApFilter}&${EmpleadoFilter}&${PendienteFilter}&${numApFilter}`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}

export async function addApApi(data, token) {
    try {
        const formData = new FormData();

        formData.append('BadgeSolicitante', data.BadgeSolicitante);
        formData.append('TipoAp', data.TipoAp);
        formData.append('Empleado', data.Empleado);
        //formData.append('File', data.ImagenAp);

        const url = `${BASE_API}v1/Ap/`;
        //const url = `http://localhost/testapi.php`;
        //const url = `https://skyverse.skycombpo.com/subirdefinitivo.php`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        };

        const response = await fetch(url, params);
        console.log(response)
        const result = await response.json();
        console.log(result)

        return result;
    } catch (error) {
        throw error;
    }
}

export async function saveDocumentApi(data, ap_id, token) {
    try {
        const formData = new FormData();

        formData.append('BadgeSolicitante', data.BadgeSolicitante);
        formData.append('TipoAp', data.TipoAp);
        formData.append('Empleado', data.Empleado);
        formData.append('ApID', ap_id);
        formData.append('File', data.ImagenAp);

        //const url = `${BASE_API}v1/Ap/`;
        //const url = `http://localhost/testapi.php`;
        const url = `https://skyverse.skycombpo.com/subirdefinitivo.php`;
        const params = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        };

        const response = await fetch(url, params);
        console.log(response)
        const result = await response.json();
        console.log(result)

        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateApApi(data, id, token) {
    try {
        const url = `${BASE_API}v1/Ap/${id}/`;
        const params = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
