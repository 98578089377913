import { list_recargables } from "../utilities/constants";

export const sidebar_menu = {
    "staff": [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            to: '/'
        },
        {
            label: 'SkyRoster',
            icon: 'pi pi-users',
            items: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-chart-bar',
                    to: '/Skyroster/'
                },
                {
                    label: 'Master Roster',
                    icon: 'pi pi-user',
                    to: '/Skyroster/MasterRosterReport'
                },
                {
                    label: 'Report Roster Update',
                    icon: 'pi pi-folder-open',
                    to: '/Skyroster/ReportUpdates',
                    badge: localStorage.getItem("rosterUpdate")
                },
                {
                    label: 'Agregar Empleado',
                    icon: 'pi pi-user-plus',
                    to: '/Skyroster/Add'
                }
            ]
        },
        {
            label: 'SkyTracking',
            icon: 'pi pi-calendar',
            items: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-chart-bar',
                    to: '/Skytracking/'
                },
                {
                    label: 'SkyTemp',
                    icon: 'pi pi-chart-bar',
                    to: '/Skytracking/SkyTemp/'
                },
                /* {
                    label: 'Reporte Ausentismo PBi',
                    icon: 'pi pi-chart-bar',
                    to: '/Skytracking/AusentismoBi/'
                }, */
                {
                    label: 'Horas Supervisor',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/ReporteHoras'
                },
                {
                    label: 'Horas para Corte',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/CorteHours'
                },
                {
                    label: 'Horas por Empleado',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/EmployeeHours'
                },
                {
                    label: 'Horas por Equipo Asignado',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/TeamHours'
                },
                {
                    label: 'Marcas Rechazadas',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/MarcasRechazadas',
                    badge: localStorage.getItem("marcas")
                },
                {
                    label: 'Horas Personales',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/HorasPersonales/'
                },
                {
                    label: 'Excepciones',
                    icon: 'pi pi-hourglass',
                    to: '/Skytracking/ReporteExcepciones'
                },
                {
                    label: 'SkyTemp Excepciones',
                    icon: 'pi pi-hourglass',
                    to: 'https://forms.office.com/pages/responsepage.aspx?id=1lgn1sGKlUqcD8ZmYBqsyci9-O_FctNDjL0C2LhF6VFURFYyR084MFpBSjlYRjAwTzRBSDQ3MkRTVC4u'
                }
            ]
        },
        {
            label: 'SkyAp',
            icon: 'pi pi-paperclip',
            items: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-chart-bar',
                    to: '/Skyap/'
                },
                {
                    label: 'Crear AP',
                    icon: 'pi pi-user-plus',
                    to: '/Skyap/Add'
                },
                {
                    label: 'Reporte AP',
                    icon: 'pi pi-table',
                    to: '/Skyap/ReportAps'
                },
                {
                    label: 'Reporte Attrition AP',
                    icon: 'pi pi-table',
                    to: '/Skyap/ReportAttritionAps'
                },
                {
                    label: 'Reporte de AP Empleado',
                    icon: 'pi pi-table',
                    to: '/Skyap/ReportPersonalAp'
                }
            ]
        },
        {
            label: 'SAM',
            icon: 'pi pi-paperclip',
            items: [
                {
                    label: 'Pre-Leads',
                    icon: 'pi pi-user-plus',
                    to: '/SAM'
                },
                {
                    label: 'SAM Leads',
                    icon: 'pi pi-table',
                    to: '/SAM/supervisor'
                },
                {
                    label: 'Documentacion',
                    icon: 'pi pi-table',
                    to: '/SAM/generalistas'
                }
            ]
        },
        {
            label: 'SIA',
            icon: 'pi pi-paperclip',
            items: [
                {
                    label: 'Reporte de Componente',
                    icon: 'pi pi-user-plus',
                    to: '/SIA'
                },
                {
                    label: 'Reporte de Prestamo',
                    icon: 'pi pi-user-plus',
                    to: '/SIA/reporte_prestamo'
                },
                {
                    label: 'Agregar Componente',
                    icon: 'pi pi-table',
                    to: '/SIA/componente'
                },
                {
                    label: 'Tipo Componente',
                    icon: 'pi pi-apple',
                    to: '/SIA/tipo_componente'
                },
                {
                    label: 'Prestamo',
                    icon: 'pi pi-table',
                    to: '/SIA/prestamo'
                }
            ]
        },
        {
            label: 'Reportes de Soporte',
            icon: 'pi pi-book',
            items: [
                {
                    label: 'Ausentismo',
                    icon: 'pi pi-user-minus',
                    to: '/Report'
                },
                /* {
                    label: 'Ausentismo WFM',
                    icon: 'pi pi-user-minus',
                    to: '/ReportWFM'
                }, */
            ]
        },
        /* {
            label: 'Hierarchy',
            icon: 'pi pi-align-left',
            items: [
                {
                    label: 'Submenu 1',
                    icon: 'pi pi-align-left',
                    items: [
                        {
                            label: 'Submenu 1.1',
                            icon: 'pi pi-align-left',
                            items: [
                                { label: 'Submenu 1.1.1', icon: 'pi pi-align-left' },
                                { label: 'Submenu 1.1.2', icon: 'pi pi-align-left' },
                                { label: 'Submenu 1.1.3', icon: 'pi pi-align-left' }
                            ]
                        },
                        {
                            label: 'Submenu 1.2',
                            icon: 'pi pi-align-left',
                            items: [
                                { label: 'Submenu 1.2.1', icon: 'pi pi-align-left' },
                                { label: 'Submenu 1.2.2', icon: 'pi pi-align-left' }
                            ]
                        }
                    ]
                },
                {
                    label: 'Submenu 2',
                    icon: 'pi pi-align-left',
                    items: [
                        {
                            label: 'Submenu 2.1',
                            icon: 'pi pi-align-left',
                            items: [
                                { label: 'Submenu 2.1.1', icon: 'pi pi-align-left' },
                                { label: 'Submenu 2.1.2', icon: 'pi pi-align-left' },
                                { label: 'Submenu 2.1.3', icon: 'pi pi-align-left' }
                            ]
                        },
                        {
                            label: 'Submenu 2.2',
                            icon: 'pi pi-align-left',
                            items: [
                                { label: 'Submenu 2.2.1', icon: 'pi pi-align-left' },
                                { label: 'Submenu 2.2.2', icon: 'pi pi-align-left' }
                            ]
                        }
                    ]
                }
            ]
        }, */
        {
            label: 'SkyPanel',
            icon: 'pi pi-cog',
            to: '/Skypanel/'
        },
    ],
    "supervisor": [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            to: '/'
        },
        {
            label: 'SkyRoster',
            icon: 'pi pi-users',
            items: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-chart-bar',
                    to: '/Skyroster/'
                },
                {
                    label: 'Master Roster',
                    icon: 'pi pi-user',
                    to: '/Skyroster/MasterRosterReport'
                }
            ]
        },
        {
            label: 'SkyTracking',
            icon: 'pi pi-calendar',
            items: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-chart-bar',
                    to: '/Skytracking/'
                },
                {
                    label: 'SkyTemp',
                    icon: 'pi pi-chart-bar',
                    to: '/Skytracking/SkyTemp/'
                },
                /* {
                    label: 'Reporte Ausentismo PBi',
                    icon: 'pi pi-chart-bar',
                    to: '/Skytracking/AusentismoBi/'
                }, */
                {
                    label: 'Horas para Corte',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/CorteHours'
                },
                {
                    label: 'Horas Supervisor',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/ReporteHoras'
                },
                {
                    label: 'Marcas Rechazadas',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/MarcasRechazadas',
                    badge: localStorage.getItem("marcas")
                },
                {
                    label: 'Horas Personales',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/HorasPersonales/'
                },
                {
                    label: 'Excepciones',
                    icon: 'pi pi-hourglass',
                    to: '/Skytracking/ReporteExcepciones'
                },
                {
                    label: 'SkyTemp Excepciones',
                    icon: 'pi pi-hourglass',
                    to: 'https://forms.office.com/pages/responsepage.aspx?id=1lgn1sGKlUqcD8ZmYBqsyci9-O_FctNDjL0C2LhF6VFURFYyR084MFpBSjlYRjAwTzRBSDQ3MkRTVC4u'
                }
            ]
        },
        {
            label: 'SkyAp',
            icon: 'pi pi-paperclip',
            items: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-chart-bar',
                    to: '/Skyap/'
                },
                {
                    label: 'Crear AP',
                    icon: 'pi pi-user-plus',
                    to: '/Skyap/Add'
                },
                {
                    label: 'Reporte AP',
                    icon: 'pi pi-table',
                    to: '/Skyap/ReportAps'
                }
            ]
        },
        {
            label: 'Reportes de Soporte',
            icon: 'pi pi-book',
            items: [
                {
                    label: 'Ausentismo',
                    icon: 'pi pi-user-minus',
                    to: '/Report'
                },
            ]
        },
    ],
    "agent": [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            to: '/'
        },
        {
            label: 'SkyTracking',
            icon: 'pi pi-calendar',
            items: [
                {
                    label: 'Horas Personales',
                    icon: 'pi pi-clock',
                    to: '/Skytracking/HorasPersonales/'
                },
            ]
        },
    ]
}