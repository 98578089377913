import React, { useEffect, useState } from 'react';

export function ReportAusentismo() {
    const [badgeUser, setBadgeUser] = useState(null);
    const [showMoney, setShowMoney] = useState(false);

    useEffect(() => {
        setBadgeUser(localStorage.getItem('badge'));
    }, []);

    useEffect(() => {

        switch (badgeUser) {
            case 'LT6571':
            case 'SV2091':
            case 'LTSC5914':
            case 'LTSC5915':
            case 'SV6486':
            case 'SV6676':
            case 'SV6737':
            case 'SV6569':
            case 'LTSC4894':
            case 'SV4378':
            case 'SV2306':
            case 'SV087':
            case 'SV1753':
            case 'LT4846':
            case 'LT1127':
            case 'LTX2988':
            case 'LT5098':
            case 'LT6459':
            case 'LT6665':
            case 'LT5768':
            case 'LT841':
            case 'SV3975':
            case 'SV2258':
                setShowMoney(true);
                break;

            default:
                break;
        }
    }, [badgeUser]);

    return (
        <>
            {showMoney ? (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h3>Reporte Ausentismo</h3>
                            <p>Aqui se muestra el reporte de ausentismo de power bi</p>

                            <iframe
                                title="Abs Desktime Accounts v.Directors"
                                width="100%"
                                height="1080"
                                src="
https://app.powerbi.com/view?r=eyJrIjoiZjY2ZmM0MDMtNTZhYS00OTllLTg3YTUtODU1MDVkYzJjNTE0IiwidCI6ImQ2Mjc1OGQ2LThhYzEtNGE5NS05YzBmLWM2NjY2MDFhYWNjOSJ9"
                                frameborder="0"
                                allowFullScreen="true"
                            ></iframe>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h3>Reporte Ausentismo</h3>
                            <p>Aqui se muestra el reporte de ausentismo de power bi</p>

                            <iframe
                                title="Abs Desktime Accounts v.Supervisor"
                                width="100%"
                                height="1080"
                                src="
        https://app.powerbi.com/view?r=eyJrIjoiM2U0MmU1ODYtOTZkNS00M2QyLTlhZDMtN2RlYjQxYzEzYTUzIiwidCI6ImQ2Mjc1OGQ2LThhYzEtNGE5NS05YzBmLWM2NjY2MDFhYWNjOSJ9"
                                frameborder="0"
                                allowFullScreen="true"
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
