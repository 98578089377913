import React from 'react';

export function ReportAusentismoWFM() {
    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h3>Reporte Ausentismo</h3>
                        <p>Aqui se muestra el reporte de ausentismo de power bi</p>

                        <iframe
                            title="Abs Desktime Accounts v.Directors"
                            width="100%"
                            height="1080"
                            src="
https://app.powerbi.com/view?r=eyJrIjoiZjY2ZmM0MDMtNTZhYS00OTllLTg3YTUtODU1MDVkYzJjNTE0IiwidCI6ImQ2Mjc1OGQ2LThhYzEtNGE5NS05YzBmLWM2NjY2MDFhYWNjOSJ9"
                            frameborder="0"
                            allowFullScreen="true"
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
