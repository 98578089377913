import { useState } from "react"
import {addTipoComponenteSiaApi, getTipoComponenteSiasApi, updateTipoComponenteSiaApi} from "../api/skyinventoryadministrator/tipocomponentesia"
import {useAuth} from "./useAuth"

export function useTipoComponenteSIA () {

    const [error, setError] = useState(false)
    const [loadingTipoComponente, setLoadingTipoComponente] = useState(false)
    const [listTipoComponente, setListTipoComponente] = useState(null)

    const {auth} = useAuth()

    const SaveTipoComponente = async (FormData) => {
        try {
            setLoadingTipoComponente(true)

            await addTipoComponenteSiaApi(FormData, auth.token);

            setLoadingTipoComponente(false)
        } catch (error) {
            setLoadingTipoComponente(false)
            setError(error)
        }
    }

    const EditTipoComponente = async (FormData, id) => {
        try {
            setLoadingTipoComponente(true)

            await updateTipoComponenteSiaApi(FormData, id, auth.token);

            setLoadingTipoComponente(false)
        } catch (error) {
            setLoadingTipoComponente(false)
            setError(error)
        }
    }

    const GetTipoComponentes = async () => {
        try {
            setLoadingTipoComponente(true)
            const response_api = await getTipoComponenteSiasApi(auth.token)

            setListTipoComponente(response_api)
            setLoadingTipoComponente(false)
        } catch (error) {
            setLoadingTipoComponente(false)
            setError(error)
        }
    }

    return {
        error,
        loadingTipoComponente,
        listTipoComponente,
        SaveTipoComponente,
        GetTipoComponentes,
        EditTipoComponente
    }
}