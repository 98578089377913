import React, { useEffect, useRef, useState } from 'react';
import { size, filter } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import moment from "moment"
import { FilterFormAp } from '../../components/Skyap';
import { DateRangeFormAp, DespidoFormAp, SimpleForm, TrasladoFormAp, ReingresoFormAp, VacationFormAp, SancionesFormAp, DateRangeFormPartialAp } from '../../components/Skyap/FormsAp';
import { listPropiedadesApFormik } from '../../utilities/options';
import { useAp, useNotificacion, useMasterRoster, useCommon } from '../../hooks';

export function AddApPage() {
    const toast = useRef();

    const [formAp, setFormAp] = useState(null);
    const [empleadoST, setEmpleadoST] = useState(null);
    const [tipoApST, setTipoApST] = useState(null);
    const [validAp, setValidAp] = useState(false);
    const [pendingAp, setPendingAp] = useState(0);

    const navigate = useNavigate();

    const { insertHeadAp, insertDetailAp, loadingAp, onValidRepeatAp } = useAp();
    const {addNotificacion, addAsignacionNotificacion} = useNotificacion()
    const {getEmpleadoUpdateResponse} = useMasterRoster()
    const { listTiposAp, getTipoAp } = useCommon();

    const onResponseForm = async (formData) => {
        setEmpleadoST(formData.Empleado);
        setTipoApST(formData.TipoAp);
        const response_valid = await onValidRepeatAp(formData.Empleado, formData.TipoAp)
        if (size(response_valid) > 0){

            setValidAp(true)
            setPendingAp(response_valid[0].id)

        }else{

            setValidAp(false)
        }

    };

    useEffect(() => {
        (async () => {
            await getTipoAp()
        })()
    }, [])

    useEffect(() => {
        setFormAp(listFormTipoAp[tipoApST]);
    }, [tipoApST]);

    const onResponseFormAp = async (formData) => {

        const response_insert_head = await insertHeadAp({
            TipoAp: tipoApST,
            Empleado: empleadoST,
            ImagenAp: formData.ImagenAp
        });

        const response_employee = await getEmpleadoUpdateResponse(empleadoST)
        const tipo_ap_name = filter(listTiposAp, {"value": tipoApST})

        const response_notificacion = await addNotificacion({
            Modulo: 3,
            Descripcion: `Ap Numero ${response_insert_head.id} Ingresada Correctamente, Empleado ${response_employee.Payroll} - ${response_employee[2]} - ${tipo_ap_name[0].name}`,
        })

        await addAsignacionNotificacion({
            Notificacion: response_notificacion.id,
            BadgeAsignacion: 'LTSC5915',
            Estado: 0
        })

        await addAsignacionNotificacion({
            Notificacion: response_notificacion.id,
            BadgeAsignacion: 'LTSC5914',
            Estado: 0
        })

        await addAsignacionNotificacion({
            Notificacion: response_notificacion.id,
            BadgeAsignacion: 'LT2262',
            Estado: 0
        })

        await addAsignacionNotificacion({
            Notificacion: response_notificacion.id,
            BadgeAsignacion: response_employee[10],
            Estado: 0
        })
        await addAsignacionNotificacion({
            Notificacion: response_notificacion.id,
            BadgeAsignacion: response_employee[11],
            Estado: 0
        })
        await addAsignacionNotificacion({
            Notificacion: response_notificacion.id,
            BadgeAsignacion: response_employee[12],
            Estado: 0
        })
        

        let cambio_horario = {
            L: {
                HoraInicio: 0,
                HoraFinal: 0,
                Almuerzo: 0,
                horasDiurnas: 0,
                horasNocturnas: 0
            },
            M: {
                HoraInicio: 0,
                HoraFinal: 0,
                Almuerzo: 0,
                horasDiurnas: 0,
                horasNocturnas: 0
            },
            X: {
                HoraInicio: 0,
                HoraFinal: 0,
                Almuerzo: 0,
                horasDiurnas: 0,
                horasNocturnas: 0
            },
            J: {
                HoraInicio: 0,
                HoraFinal: 0,
                Almuerzo: 0,
                horasDiurnas: 0,
                horasNocturnas: 0
            },
            V: {
                HoraInicio: 0,
                HoraFinal: 0,
                Almuerzo: 0,
                horasDiurnas: 0,
                horasNocturnas: 0
            },
            S: {
                HoraInicio: 0,
                HoraFinal: 0,
                Almuerzo: 0,
                horasDiurnas: 0,
                horasNocturnas: 0
            },
            D: {
                HoraInicio: 0,
                HoraFinal: 0,
                Almuerzo: 0,
                horasDiurnas: 0,
                horasNocturnas: 0
            }
        };

        let insert_horario = false

        if (response_insert_head.TipoAp == 28){

            await insertDetailAp({
                Ap: response_insert_head.id,
                PropiedadAp: 22,
                Valor: 1
            });

        }

        for await (const prop of Object.keys(formData)) {
            if (prop == 'FechasEfectivas') {
                const fechaInicio = new Date(formData.FechasEfectivas[0]);
                const fechaFinal = new Date(formData.FechasEfectivas[1]);
                await insertDetailAp({
                    Ap: response_insert_head.id,
                    PropiedadAp: 12,
                    Valor: `${fechaInicio.getFullYear()}-${fechaInicio.getMonth() + 1}-${fechaInicio.getDate()}`
                });

                await insertDetailAp({
                    Ap: response_insert_head.id,
                    PropiedadAp: 13,
                    Valor: `${fechaFinal.getFullYear()}-${fechaFinal.getMonth() + 1}-${fechaFinal.getDate()}`
                });
            } else if (prop == 'FechaEfectiva') {
                await insertDetailAp({
                    Ap: response_insert_head.id,
                    PropiedadAp: 11,
                    Valor: `${formData[prop].getFullYear()}-${formData[prop].getMonth() + 1}-${formData[prop].getDate()}`
                });
            } else if (prop == 'TipoAttrition') {

                const fecha_ingreso = moment(response_employee[14])
                const fecha_entrenamiento = moment(response_employee[15])
                const fecha_produccion = moment(response_employee[16])
                const fecha_actual = moment()

                if (fecha_actual <= fecha_produccion){
                    await insertDetailAp({
                        Ap: response_insert_head.id,
                        PropiedadAp: 25,
                        Valor: 'Training'
                    });
                }else{
                    await insertDetailAp({
                        Ap: response_insert_head.id,
                        PropiedadAp: 25,
                        Valor: 'Production'
                    });
                }

            } else {
                const prop_horario = prop.split('_');

                if (size(prop_horario) > 1) {
                    insert_horario = true
                    if (prop.includes('Inicio') || prop.includes('Final')) {
                        let inicio_nocturnidad = new Date();
                        inicio_nocturnidad.setHours(19);
                        inicio_nocturnidad.setMinutes(0);
                        inicio_nocturnidad.setSeconds(0);

                        let fin_nocturnidad = new Date();
                        fin_nocturnidad.setHours(6);
                        fin_nocturnidad.setMinutes(0);
                        fin_nocturnidad.setSeconds(0);

                        let noche_mill_diff = 0;
                        let noche_diff = 0;

                        if (formData[prop] > fin_nocturnidad && formData[prop] < inicio_nocturnidad) {
                            console.log('Hora Dia');
                        } else {
                            if (formData[prop] > inicio_nocturnidad) {
                                noche_mill_diff = Math.abs(formData[prop] - inicio_nocturnidad);
                                noche_diff = noche_mill_diff / 3600000;
                                if (prop.includes('Inicio')) {
                                    noche_diff = 5 - noche_diff;
                                }
                            } else {
                                noche_mill_diff = Math.abs(fin_nocturnidad - formData[prop]);
                                noche_diff = noche_mill_diff / 3600000;
                                if (prop.includes('Final')) {
                                    noche_diff = 6 - noche_diff;
                                }
                            }
                            cambio_horario[prop_horario[1]]['horasNocturnas'] += parseFloat(noche_diff.toFixed(3));
                        }
                    }

                    cambio_horario[prop_horario[1]][prop_horario[0]] = formData[prop];
                } else {

                    await insertDetailAp({
                        Ap: response_insert_head.id,
                        PropiedadAp: listPropiedadesApFormik[prop],
                        Valor: formData[prop]
                    });
                }
            }
        }

        if(insert_horario){

            await insertDetailAp({
                Ap: response_insert_head.id,
                PropiedadAp: 20,
                Valor: JSON.stringify(cambio_horario)
            });

        }

        toast.current.show({
            severity: 'success',
            summary: 'AP',
            detail: 'Ap Ingresada Correctamente',
            life: 3000
        });

        setTimeout(() => {
            navigate('/SkyAp/ReportAps');
        }, 1000);
    };

    const listFormTipoAp = {
        1: <DateRangeFormAp response={onResponseFormAp} />,
        2: <DateRangeFormPartialAp response={onResponseFormAp} />,
        3: <DespidoFormAp response={onResponseFormAp} />,
        4: <DespidoFormAp response={onResponseFormAp} />,
        5: <DespidoFormAp response={onResponseFormAp} />,
        19: <DespidoFormAp response={onResponseFormAp} />,
        6: <SimpleForm response={onResponseFormAp} />,
        7: <DateRangeFormAp response={onResponseFormAp} />,
        8: <SimpleForm response={onResponseFormAp} />,
        9: <SimpleForm response={onResponseFormAp} />,
        10: <SimpleForm response={onResponseFormAp} />,
        11: <SimpleForm response={onResponseFormAp} />,
        12: <SimpleForm response={onResponseFormAp} />,
        13: <DateRangeFormAp response={onResponseFormAp} />,
        14: <SimpleForm response={onResponseFormAp} />,
        15: <DateRangeFormAp response={onResponseFormAp} />,
        17: <DateRangeFormPartialAp response={onResponseFormAp} />,
        18: <SimpleForm response={onResponseFormAp} />,
        20: <TrasladoFormAp response={onResponseFormAp} empleado={empleadoST} tipoAp={tipoApST} />,
        21: <ReingresoFormAp response={onResponseFormAp} />,
        22: <VacationFormAp response={onResponseFormAp} />,
        23: <SancionesFormAp response={onResponseFormAp} />,
        24: <TrasladoFormAp response={onResponseFormAp} promotion={true} tipoAp={tipoApST} />,
        25: <ReingresoFormAp response={onResponseFormAp} />,
        26: <TrasladoFormAp response={onResponseFormAp} tipoAp={tipoApST} />,
        27: <SimpleForm response={onResponseFormAp} />,
        28: <SimpleForm response={onResponseFormAp} />,
        30: <ReingresoFormAp response={onResponseFormAp} tipoAp={tipoApST} />,
        31: <DateRangeFormAp response={onResponseFormAp} />,
        32: <DespidoFormAp response={onResponseFormAp} />,
        33: <SimpleForm response={onResponseFormAp} />,
        34: <SimpleForm response={onResponseFormAp} />,
        35: <SimpleForm response={onResponseFormAp} />,
        36: <SimpleForm response={onResponseFormAp} />,
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Agrear Ap | SkyAp</h5>
                        <p>Completa todos los valores para poder ingresar una AP</p>
                        <FilterFormAp response={onResponseForm} />
                    </div>
                </div>
            </div>

            {loadingAp && (
                <div className="grid">
                    <div className="col-12">
                        <i className="pi pi-spinner pi-spin"></i>
                    </div>
                </div>
            )}

            {validAp ? (
                <div className='grid mt-3'>
                    <div className='col-12 text-center'>
                        <h5>Este agente tiene la AP # {pendingAp} pendiente, Por favor realiza la gestion para poder ingresar una nueva</h5>
                    </div>
                </div>
            ) : (
                <>
                    {formAp && (
                        <div className="grid">
                            <div className="col-12">
                                <div className="card p-5">
                                    <h5>Formulario de AP</h5>
                                    <p>Llena todos los campos para poder guardar la accion de personal</p>

                                    {formAp}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

        </>
    );
}
