import React from "react";
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Button} from "primereact/button"

export function TableTipoComponente ({ listTipoComponenteShow, editPadre }) {

    const accionesTemplate = (rowData) => {
        return (
            <>
            <Button severity="warning" type="button" onClick={() => editPadre(rowData)}>
                <i className="pi pi-pencil"></i>
            </Button>
            </>
        )
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <DataTable value={listTipoComponenteShow}>
                        <Column header="Tipo de Componente" field="TipoComponente"></Column>
                        <Column header="Prefijo" field="Prefijo"></Column>
                        <Column header="Correlativo" field="Correlativo"></Column>
                        <Column header="Acciones" body={accionesTemplate}></Column>
                    </DataTable>

                </div>
            </div>

        </div>
    )
}