import React from 'react'
import {useFormik} from "formik"
import * as Yup from "yup"
import {InputTextarea} from "primereact/inputtextarea"
import { Button } from 'primereact/button'

export function ModalApplicant() {

    const dict_bd = {
        Comentario: 2,
        Recomendaciones: 7,
        Compromiso: 9,
    }

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: (formValue) => {
            console.log(formValue)

            for (const key_input of Object.keys(formValue)){

                if(dict_bd[key_input] != undefined){

                    let data_form_nota = {
                        "IDReunion": 10,
                        "Campo": dict_bd[key_input],
                        "Valor": formValue[key_input]
                    }
    
                    console.log(data_form_nota)
                    
                }

                
            }
        }
    })

  return (
    <>
    <h3>Hola modal</h3>
    <form onSubmit={formik.handleSubmit}>

        <div className='grid my-3'>
            <div className='col-12'>
                <span className='p-float-label'>
                    <InputTextarea id='Comentario' name='Comentario' className='w-full' value={formik.values.Comentario} onChange={(e) => formik.setFieldValue("Comentario", e.target.value)} />
                    <label htmlFor='Comentario'>Comentario</label>
                </span>
            </div>
        </div>
        <div className='grid my-3'>
            <div className='col-12'>
                <span className='p-float-label'>
                    <InputTextarea id='Recomendaciones' name='Recomendaciones' className='w-full' value={formik.values.Recomendaciones} onChange={(e) => formik.setFieldValue("Recomendaciones", e.target.value)} />
                    <label htmlFor='Recomendaciones'>Recomendaciones</label>
                </span>
            </div>
        </div>
        <div className='grid my-3'>
            <div className='col-12'>
                <span className='p-float-label'>
                    <InputTextarea id='Compromiso' name='Compromiso' className='w-full' value={formik.values.Compromiso} onChange={(e) => formik.setFieldValue("Compromiso", e.target.value)} />
                    <label htmlFor='Compromiso'>Compromiso</label>
                </span>
            </div>
        </div>
        <div className='grid my-3'>
            <div className='col-12'>
                <Button severity='primary' type='submit'>Guardar</Button>
            </div>
        </div>

    </form>
    </>
  )
}

function initialValues(){
    return {
        Comentario: "",
        Recomendaciones: "",
        Compromiso: "",
        Badge: "SV2091",
    }
}
function validationSchema(){
    return {
        Comentario: Yup.string().required(true),
        Recomendaciones: Yup.string().required(true),
        Compromiso: Yup.string().required(true),
        Badge: Yup.string().required(true),
    }
}