import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTipoComponenteSIA } from '../../hooks';
import { TableTipoComponente } from '../../components/skyinventoryadministrator';

export function TipoComponentePage() {
    const [refresh, setRefresh] = useState(false);
    const [idTipoComponente, setIdTipoComponente] = useState(0);

    const toast = useRef();

    const { SaveTipoComponente, GetTipoComponentes, listTipoComponente, loadingTipoComponente, EditTipoComponente } = useTipoComponenteSIA();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {

            if (idTipoComponente > 0){
                await EditTipoComponente(formValue, idTipoComponente);
            }else{

                await SaveTipoComponente(formValue);

            }

            toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Tipo de Componente Agregado Correctamente' });

            onCleanField();

            onRefresh();
        },
        validate: () => {
            if (formik.errors.TipoComponente || formik.errors.Prefijo || formik.errors.Correlativo) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debes completar todos los campos' });
            }
        },
        validateOnChange: false
    });

    const onRefresh = () => setRefresh((prev) => !prev);

    useEffect(() => {
        (async () => {
            await GetTipoComponentes();
        })();
    }, [refresh]);

    const onCleanField = () => {
        formik.setFieldValue('Prefijo', '');
        formik.setFieldValue('TipoComponente', '');
        formik.setFieldValue('Correlativo', '');
        setIdTipoComponente(0);
    };

    const onEditComponent = (rowData) => {
        formik.setFieldValue('TipoComponente', rowData.TipoComponente);
        formik.setFieldValue('Prefijo', rowData.Prefijo);
        formik.setFieldValue('Correlativo', rowData.Correlativo);
        setIdTipoComponente(rowData.id);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card my-3">
                        <h5>Tipos de Componente</h5>
                        <p>Aqui puedes agregar los tipos de componentes que se almacenaran dentro del sistema de inventario</p>

                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid my-5">
                                <div className="col-4">
                                    <span className="p-float-label">
                                        <InputText id="TipoComponente" name="TipoComponente" value={formik.values.TipoComponente} onChange={formik.handleChange} className="w-full" />
                                        <label htmlFor="TipoComponente">
                                            Tipo de Componente <b style={{ color: 'red' }}>*</b>
                                        </label>
                                    </span>
                                </div>
                                <div className="col-4">
                                    <span className="p-float-label">
                                        <InputText id="Prefijo" name="Prefijo" value={formik.values.Prefijo} onChange={formik.handleChange} className="w-full" />
                                        <label htmlFor="Prefijo">
                                            Prefijo <b style={{ color: 'red' }}>*</b>
                                        </label>
                                    </span>
                                </div>
                                <div className="col-4">
                                    <span className="p-float-label">
                                        <InputText id="Correlativo" name="Correlativo" keyfilter="int" value={formik.values.Correlativo} onChange={(e) => formik.setFieldValue('Correlativo', e.target.value)} className="w-full" />
                                        <label htmlFor="Correlativo">
                                            Correlativo <b style={{ color: 'red' }}>*</b>
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right">
                                    
                                    {idTipoComponente > 0 ? (
                                        <>
                                            <span className='mr-3'>Editando Registro</span>
                                            <span className='mr-3'>
                                                <Button severity="danger" type="button" onClick={onCleanField}>
                                                    <i className="pi pi-times"></i>
                                                </Button>
                                            </span>
                                        </>
                                    ) : (
                                        <span className='mr-3'>Agregando Nuevo</span>
                                    )}
                                    <Button type="submit" severity="primary">
                                        Guardar Tipo Componente
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {loadingTipoComponente ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <TableTipoComponente listTipoComponenteShow={listTipoComponente} editPadre={onEditComponent} />
            )}
        </>
    );
}

function initialValues() {
    return {
        Prefijo: '',
        TipoComponente: '',
        Correlativo: ''
    };
}

function validationSchema() {
    return {
        Prefijo: Yup.string().required(true),
        TipoComponente: Yup.string().required(true),
        Correlativo: Yup.string().required(true)
    };
}
