import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    DashboardSkytrackingPage,
    HorasSupervisorPage,
    ExcepcionesPage,
    HorasPersonalesPage,
    MarcasRechazadasPage,
    ExcepcionesMasivasPage,
    ReportPowerBiPage,
    ReportSkyTempPage,
    SkytrackingReportPage,
    ReportExceptionsPage,
    SkyEmployeeHoursPage,
    SkyTeamHoursPage,
    SkyCorteHoursPage
} from '../pages/skytracking';
import { AddEmployeePage, RosterUpdatePage, ReportUpdatesPage, MasterRosterPage, FichaEmpleadoPage, DashboardSkyRosterPage, MasterRosterAutoPage } from '../pages/skyroster';
import {
    SkypanelPage,
    SkytrackingTempPage,
    CargarHorasPage,
    CargarRosterPage,
    CargarHorariosPage,
    FacturacionPage,
    EditarMarcaPage,
    RosterWFMPage,
    CalcAuxHorasPage,
    CalcularProgramadasPage,
    MasterRosterPanelPage,
    HorasPlanillaPage,
    LoadRosterPage,
    PanelRosterPage,
    ReporteHorasStaffingPage,
    EditarEmpleadoPage,
    ReportePayrollPage,
    ReportHorasPage
} from '../pages/skypanel';
import { AddApPage, ReportApPage, ReportPersonalApPage, ApImplementPage, ReportApAutoPage, ReportApAttrition } from '../pages/skyap';
import { AplicantesReclutamiento, AplicantesSupervisor, AplicantesDocumentacion, KanbanApplicantReportPage } from '../pages/skyapplicantmanagement';
import { ComponentePage, PrestamoPage, ReporteComponentePage, ReportePrestamosPage, RecibirPrestamoPage, TipoComponentePage } from '../pages/skyinventoryadministrator';
import {ReportAusentismo, ReportAusentismoWFM} from "../pages/skyreport"
import ApDetailPrint from '../pages/skyap/ApDetailPrint';
import { UserPerfilPage } from '../pages/common';

export default function Navigator() {
    return (
        <Routes>
            {/* main common routes */}
            <Route path="/" exact="true" element={<DashboardSkytrackingPage />} />

            {/* Skytracking Routes */}
            <Route path="/Skytracking/Horas" exact="true" element={<HorasSupervisorPage />} />
            <Route path="/Skytracking/MarcasRechazadas" exact="true" element={<MarcasRechazadasPage />} />
            <Route path="/Skytracking/HorasPersonales" exact="true" element={<HorasPersonalesPage />} />
            <Route path="/Skytracking/Excepciones" exact="true" element={<ExcepcionesPage />} />
            <Route path="/Skytracking/ExcepcionesMasivas" exact="true" element={<ExcepcionesMasivasPage />} />
            <Route path="/Skytracking/AusentismoBi" exact="true" element={<ReportPowerBiPage />} />
            <Route path="/Skytracking/SkyTemp" exact="true" element={<ReportSkyTempPage />} />
            <Route path="/Skytracking/ReporteHoras" exact="true" element={<SkytrackingReportPage />} />
            <Route path="/Skytracking/ReporteExcepciones" exact="true" element={<ReportExceptionsPage />} />
            <Route path="/Skytracking/EmployeeHours" exact="true" element={<SkyEmployeeHoursPage />} />
            <Route path="/Skytracking/TeamHours" exact="true" element={<SkyTeamHoursPage />} />
            <Route path="/Skytracking/CorteHours" exact="true" element={<SkyCorteHoursPage />} />

            {/* Skyroster Routes */}
            <Route path="/Skyroster/" exact="true" element={<DashboardSkyRosterPage />} />
            <Route path="/Skyroster/Add/*" exact="true" element={<AddEmployeePage />} />
            <Route path="/Skyroster/ReportUpdates" exact="true" element={<ReportUpdatesPage />} />
            <Route path="/Skyroster/MasterRoster" exact="true" element={<MasterRosterPage />} />
            <Route path="/Skyroster/MasterRosterReport" exact="true" element={<MasterRosterAutoPage />} />
            <Route path="/Skyroster/RosterUpdate/:idEmpleado" exact="true" element={<RosterUpdatePage />} />
            <Route path="/Skyroster/FichaEmpleado/:idEmpleado" exact="true" element={<FichaEmpleadoPage />} />

            {/* Skyap Routes */}
            <Route path="/Skyap/Add" exact="true" element={<AddApPage />} />
            <Route path="/Skyap/ReportAp" exact="true" element={<ReportApPage />} />
            <Route path="/Skyap/ReportPersonalAp" exact="true" element={<ReportPersonalApPage />} />
            <Route path="/Skyap/ApImplementation" exact="true" element={<ApImplementPage />} />
            <Route path="/Skyap/ReportAps" exact="true" element={<ReportApAutoPage />} />
            <Route path="/Skyap/ReportAttritionAps" exact="true" element={<ReportApAttrition />} />
            <Route path="/Skyap/:numAp" exact="true" element={<ApDetailPrint />} />

            {/* Skypanel Routes */}
            <Route path="/Skypanel" exact="true" element={<SkypanelPage />} />
            <Route path="/Skypanel/Stafftime" exact="true" element={<ReporteHorasStaffingPage />} />
            <Route path="/Skypanel/AttendanceReport" exact="true" element={<ReportHorasPage />} />
            <Route path="/Skypanel/Payroll" exact="true" element={<ReportePayrollPage />} />
            <Route path="/Skypanel/SkytrackingTemp" exact="true" element={<SkytrackingTempPage />} />
            <Route path="/Skypanel/CargarHoras" exact="true" element={<CargarHorasPage />} />
            <Route path="/Skypanel/CargarRoster" exact="true" element={<CargarRosterPage />} />
            <Route path="/Skypanel/CargarHorarios" exact="true" element={<CargarHorariosPage />} />
            <Route path="/Skypanel/EditarMarca" exact="true" element={<EditarMarcaPage />} />
            <Route path="/Skypanel/RosterWFM" exact="true" element={<RosterWFMPage />} />
            <Route path="/Skypanel/CalcularProgramadas" exact="true" element={<CalcularProgramadasPage />} />
            <Route path="/Skypanel/MasterRoster" exact="true" element={<MasterRosterPanelPage />} />
            <Route path="/Skypanel/PanelRoster" exact="true" element={<PanelRosterPage />} />
            <Route path="/Skypanel/HorasPlanilla" exact="true" element={<HorasPlanillaPage />} />
            <Route path="/Skypanel/LoadRosterWeek" exact="true" element={<LoadRosterPage />} />
            <Route path="/Skypanel/FacturaVirtuox" exact="true" element={<FacturacionPage />} />
            <Route path="/Skypanel/CalcAux" exact="true" element={<CalcAuxHorasPage />} />
            <Route path="/Skypanel/EditarEmpleado" exact="true" element={<EditarEmpleadoPage />} />

            {/* Skyprofile Routes */}
            <Route path="/Skyprofile/*" exact="true" element={<UserPerfilPage />} />

            {/* SkyApplicantManagement */}
            <Route path="/SAM" exact="true" element={<AplicantesReclutamiento />} />
            <Route path="/SAM/supervisor" exact="true" element={<AplicantesSupervisor />} />
            <Route path="/SAM/generalistas" exact="true" element={<AplicantesDocumentacion />} />
            <Route path="/SAM/KanbanReport" exact="true" element={<KanbanApplicantReportPage />} />

            {/* SkyInventoryAdministrator */}
            <Route path="/SIA" exact="true" element={<ReporteComponentePage />} />
            <Route path="/SIA/reporte_prestamo" exact="true" element={<ReportePrestamosPage />} />
            <Route path="/SIA/componente" exact="true" element={<ComponentePage />} />
            <Route path="/SIA/prestamo" exact="true" element={<PrestamoPage />} />
            <Route path="/SIA/prestamo/:prestamo/:empleado" exact="true" element={<RecibirPrestamoPage />} />
            <Route path="/SIA/tipo_componente" exact="true" element={<TipoComponentePage />} />

            {/* Reportes de Soporte */}
            <Route path="/Report" exact="true" element={<ReportAusentismo />} />
            <Route path="/ReportWFM" exact="true" element={<ReportAusentismoWFM />} />
        </Routes>
    );
}
