import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { FiltrosSam } from '../../components/skyapplicantmanagement';
import { useApplicant } from '../../hooks';
import { DroppableSam, DraggableSam, ModalApplicant } from '../../components/skyapplicantmanagement';

export function KanbanApplicantReportPage() {
    const toast = useRef(null);

    const [visible, setVisible] = useState(false);

    const { getReportApplicant, listApplicants, loadingApplicant } = useApplicant();

    const list_filtros = {
        FechaAplicacion: true,
        EstadoAplicante: true,
        Pauta: true,
        Idioma: true,
        NivelIdioma: true,
        Pais: true,
        Nombre: true,
        Cuenta: false,
        Job: true,
        FechaAprobacion: false,
        Referido: true
    };

    const list_required = {
        FechaAplicacion: true,
        EstadoAplicante: true
    };

    useEffect(() => {
        if (toast.current) {
            toast.current.show({
                severity: 'info',
                summary: 'Cambio de Estado',
                detail: 'Se cambió el estado del aplicante a nada'
            });
        }
    }, []);

    //console.log(listApplicants);

    const onResponseForm = async (FormData) => {
        await getReportApplicant(moment(FormData.FechaAplicacion[0]).format('YYYY-MM-DD'), moment(FormData.FechaAplicacion[1]).format('YYYY-MM-DD'), FormData.EstadoAplicante, FormData);
    };

    const [items] = useState(['1', '2', '3', '4', '5', '6', '7', '8']);
    const [activeId, setActiveId] = useState(null);

    const [itemDropZone, setItemDropZone] = useState([]);
    const [itemDropZoneLead, setItemDropZoneLead] = useState([]);
    const [itemDZRechazados, setitemDZRechazados] = useState([]);
    const [itemDZSupervisor, setitemDZSupervisor] = useState([]);
    const [itemDZFinal, setitemDZFinal] = useState([]);
    const [itemDZDocumentacion, setitemDZDocumentacion] = useState([]);
    const [itemDZPipepline, setitemDZPipepline] = useState([]);

    return (
        <>
            <Dialog
                header="Header"
                visible={visible}
                style={{ width: '50vw' }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
            >
                <ModalApplicant />
            </Dialog>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Filtros Aplicantes</h5>
                        <p>Selecciona todos los filtros a aplicar dentro del panel kanban</p>

                        <FiltrosSam filtros={list_filtros} response={onResponseForm} param_required={list_required} />
                        <Button severity="primary" type="button" onClick={(e) => setVisible(true)}>
                            Ver modal
                        </Button>
                    </div>
                </div>
            </div>

            <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <div className="grid">
                    {items.map((id) => (
                        <>
                            {itemDropZone.includes(id) ||
                            itemDropZoneLead.includes(id) ||
                            itemDZRechazados.includes(id) ||
                            itemDZSupervisor.includes(id) ||
                            itemDZFinal.includes(id) ||
                            itemDZDocumentacion.includes(id) ||
                            itemDZPipepline.includes(id) ? (
                                <></>
                            ) : (
                                <div className="col-2" key={id * 100}>
                                    <DraggableSam key={id} id={id} visibleFunc={setVisible} preLeadset={setItemDropZone} leadset={setItemDropZoneLead}>
                                        Drag {id}
                                    </DraggableSam>
                                </div>
                            )}
                        </>
                    ))}
                    <DragOverlay>
                        {activeId != null ? (

                            <DraggableSam key={activeId} id={activeId} visibleFunc={setVisible}>
                                Drag {activeId}
                            </DraggableSam>

                        ) : null}
                    </DragOverlay>
                </div>

                <div className="flex p-d-flez p-flex-wrap">
                    <div className="p-col p-md-1">
                        <DroppableSam items={itemDropZone} idDrop="PreLead" AcceptDrop={['type1', 'type2']} tituloDrop="PreLead 15 / 5" visibleFunc={setVisible} preLeadset={setItemDropZone} leadset={setItemDropZoneLead} />
                    </div>
                    <div className="p-col p-md-1">
                        <DroppableSam items={itemDZRechazados} idDrop="Rechazados" AcceptDrop={['type1', 'type2']} tituloDrop="Rechazados" visibleFunc={setVisible} preLeadset={setItemDropZone} leadset={setItemDropZoneLead} />
                    </div>
                    <div className="p-col p-md-1">
                        <DroppableSam items={itemDropZoneLead} idDrop="Lead" AcceptDrop={['type1', 'type2']} tituloDrop="Lead" visibleFunc={setVisible} preLeadset={setItemDropZone} leadset={setItemDropZoneLead} />
                    </div>
                    <div className="p-col p-md-1">
                        <DroppableSam items={itemDZSupervisor} idDrop="EntrevistaSupervisor" AcceptDrop={['type1', 'type2']} tituloDrop="Entrevista con Supervisor" visibleFunc={setVisible} preLeadset={setItemDropZone} leadset={setItemDropZoneLead} />
                    </div>
                    <div className="p-col p-md-1">
                        <DroppableSam items={itemDZFinal} idDrop="EntrevistaFinales" AcceptDrop={['type1', 'type2']} tituloDrop="Entrevista Finales" visibleFunc={setVisible} preLeadset={setItemDropZone} leadset={setItemDropZoneLead} />
                    </div>
                    <div className="p-col p-md-1">
                        <DroppableSam items={itemDZDocumentacion} idDrop="Documentacion" AcceptDrop={['type1', 'type2']} tituloDrop="Documentacion" visibleFunc={setVisible} preLeadset={setItemDropZone} leadset={setItemDropZoneLead} />
                    </div>
                    <div className="p-col p-md-1">
                        <DroppableSam items={itemDZPipepline} idDrop="Pipepline" AcceptDrop={['type1', 'type2']} tituloDrop="Pipepline" visibleFunc={setVisible} preLeadset={setItemDropZone} leadset={setItemDropZoneLead} />
                    </div>
                </div>
            </DndContext>
        </>
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (over && over.data.current.accepts.includes(active.data.current.type)) {
            switch (over.id) {
                case 'PreLead':
                    setItemDropZone((prev) => [...prev, active.id]);

                    setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
                    setitemDZRechazados((prev) => prev.filter((item) => item !== active.id));
                    setitemDZSupervisor((prev) => prev.filter((item) => item !== active.id));
                    setitemDZFinal((prev) => prev.filter((item) => item !== active.id));
                    setitemDZDocumentacion((prev) => prev.filter((item) => item !== active.id));
                    setitemDZPipepline((prev) => prev.filter((item) => item !== active.id));
                    break;

                case 'Rechazados':
                    setitemDZRechazados((prev) => [...prev, active.id]);

                    setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZone((prev) => prev.filter((item) => item !== active.id));
                    setitemDZSupervisor((prev) => prev.filter((item) => item !== active.id));
                    setitemDZFinal((prev) => prev.filter((item) => item !== active.id));
                    setitemDZDocumentacion((prev) => prev.filter((item) => item !== active.id));
                    setitemDZPipepline((prev) => prev.filter((item) => item !== active.id));
                    break;

                case 'Lead':
                    setItemDropZoneLead((prev) => [...prev, active.id]);

                    setitemDZRechazados((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZone((prev) => prev.filter((item) => item !== active.id));
                    setitemDZSupervisor((prev) => prev.filter((item) => item !== active.id));
                    setitemDZFinal((prev) => prev.filter((item) => item !== active.id));
                    setitemDZDocumentacion((prev) => prev.filter((item) => item !== active.id));
                    setitemDZPipepline((prev) => prev.filter((item) => item !== active.id));
                    break;

                case 'EntrevistaSupervisor':
                    setitemDZSupervisor((prev) => [...prev, active.id]);

                    setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZone((prev) => prev.filter((item) => item !== active.id));
                    setitemDZRechazados((prev) => prev.filter((item) => item !== active.id));
                    setitemDZFinal((prev) => prev.filter((item) => item !== active.id));
                    setitemDZDocumentacion((prev) => prev.filter((item) => item !== active.id));
                    setitemDZPipepline((prev) => prev.filter((item) => item !== active.id));
                    break;

                case 'EntrevistaFinales':
                    setitemDZFinal((prev) => [...prev, active.id]);

                    setitemDZRechazados((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZone((prev) => prev.filter((item) => item !== active.id));
                    setitemDZSupervisor((prev) => prev.filter((item) => item !== active.id));
                    setitemDZDocumentacion((prev) => prev.filter((item) => item !== active.id));
                    setitemDZPipepline((prev) => prev.filter((item) => item !== active.id));
                    break;
                case 'Documentacion':
                    setitemDZDocumentacion((prev) => [...prev, active.id]);

                    setitemDZRechazados((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZone((prev) => prev.filter((item) => item !== active.id));
                    setitemDZSupervisor((prev) => prev.filter((item) => item !== active.id));
                    setitemDZFinal((prev) => prev.filter((item) => item !== active.id));
                    setitemDZPipepline((prev) => prev.filter((item) => item !== active.id));
                    break;
                case 'Pipepline':
                    setitemDZPipepline((prev) => [...prev, active.id]);

                    setitemDZRechazados((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
                    setItemDropZone((prev) => prev.filter((item) => item !== active.id));
                    setitemDZSupervisor((prev) => prev.filter((item) => item !== active.id));
                    setitemDZFinal((prev) => prev.filter((item) => item !== active.id));
                    setitemDZDocumentacion((prev) => prev.filter((item) => item !== active.id));
                    break;

                default:
                    break;
            }

            toast.current.show({ severity: 'info', summary: 'Cambio de Estado', detail: `Se cambio el estado del aplicante a ${over.id}` });
        } else {
            /* if (over.id == 'Lead') {
                setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
            } else {
                setItemDropZone((prev) => prev.filter((item) => item !== active.id));
            } */
        }

        setActiveId(null);
    }
}
