import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import {Button} from "primereact/button"
import { DraggableSam } from './DraggableSam';
import {sortedUniq} from "lodash"

export function DroppableSam({ children, items, idDrop, AcceptDrop, tituloDrop, descripcionDrop, visibleFunc,preLeadset,leadset }) {
    const { isOver, setNodeRef } = useDroppable({
        id: idDrop,
        data: {
            accepts: AcceptDrop
        }
    });
    
    return (
        <div ref={setNodeRef} >
            <div className="grid p-3">
                <div className="col-12">
                    <div className={`card ${isOver ? 'surface-500 text-white' : ''}`}>

                        <h5>{tituloDrop}</h5>
                        <p>{descripcionDrop}</p>
                        {sortedUniq(items).map((item) => (
                            <DraggableSam key={item} id={item} visibleFunc={visibleFunc} preLeadset={preLeadset} leadset={leadset} >
                                Drag {item}
                            </DraggableSam>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}
